<template>
  <div class="container-fluid p-4">
    <h1>
      {{ pageTitle }}
    </h1>
    <router-view></router-view>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
// import moment from "moment";
// import WebsocketHeartbeatJs from "websocket-heartbeat-js";
// let utils = require("@/assets/global/js/utils.js");

export default {
  data: function () {
    return {
      pageTitle: "Logs"
      // logWS: null,
      // logWSToken: "",
      // wsTopics: ""
    };
  }
  // computed: {
  //   ...mapGetters({
  //     getAPIServerURL: "session/getAPIServerURL",
  //     getWSServerURL: "session/getWSServerURL",
  //     getWSServerKey: "session/getWSServerKey"
  //   })
  // },
  // mounted: function () {
  //   if (this.logWS != null) {
  //     this.disconnectLogWS();
  //   }
  //   this.connectLogWS();
  // },
  // unmounted: function () {
  //   this.disconnectLogWS();
  // },
  // methods: {
  //   connectLogWS: function () {
  //     var $this = this;
  //     if ($this.wsTopics == "") {
  //       $this.wsTopics = "log_alerts";
  //     }
  //
  //     try {
  //       if ($this.logWS != null) {
  //         $this.logWS.close();
  //       }
  //     } catch (err) {
  //       // console.log(err);
  //     }
  //     $this.logWS = null;
  //
  //     let ws_url =
  //       $this.getWSServerURL + "/events/?key=" + $this.getWSServerKey;
  //
  //     var ws_ping_param = { action: 0 };
  //
  //     let log_ws = new WebsocketHeartbeatJs({
  //       url: ws_url,
  //       pingMsg: JSON.stringify(ws_ping_param),
  //       pingTimeout: 10000,
  //       pongTimeout: 20000
  //     });
  //
  //     log_ws.onopen = (e) => {
  //       console.log("log_ws: Opening ...");
  //
  //       var param = {
  //         action: 1,
  //         topics: $this.wsTopics
  //       };
  //
  //       // Subscribe camera
  //       log_ws.send(JSON.stringify(param));
  //       console.log("log_ws: Ready...");
  //     };
  //     log_ws.onerror = (e) => {
  //       console.log("log_ws: Error: ", e);
  //     };
  //     log_ws.onclose = (e) => {
  //       console.log("log_ws: Closing ...");
  //     };
  //     log_ws.onreconnect = (e) => {
  //       console.log("log_ws: Reconnecting...");
  //     };
  //     log_ws.onmessage = function (result) {
  //       // console.log(`log_ws onmessage: ${result.data}`);
  //
  //       var result_data = JSON.parse(result.data);
  //       var result_type = result_data.type;
  //       let data = null;
  //       // console.log("result_type", result_type)
  //
  //       try {
  //         if (result_type == "close") {
  //           console.log("log_ws: Received message to close websocket ...");
  //           $this.disconnectLogWS();
  //         } else if (result_type == "auth") {
  //           $this.logWSToken = result_data.session_key;
  //           // console.log('session_key: ' + cameraws_token);
  //         } else if (result_type == "error") {
  //           data = result_data.msg;
  //           console.log("error");
  //           console.log(data);
  //         } else if (result_type == "msg") {
  //           data = result_data.msg;
  //           console.log("msg");
  //           console.log(data);
  //         } else if (result_type == "info") {
  //           data = result_data.msg;
  //           console.log("info");
  //           console.log(data);
  //         } else if (result_type == "heartbeat") {
  //           console.log("heartbeat");
  //           // refreshTimesince(null);
  //           // checkEmptyEventImage();
  //         } else if (result_type == "events") {
  //           data = result_data.data;
  //
  //           try {
  //             data = utils.canopy_decoder(data, $this.logWSToken);
  //
  //             data = JSON.parse(data);
  //           } catch (err) {
  //             if (
  //               $this.logWSToken == "insecure" ||
  //               $this.logWSToken == "" ||
  //               $this.logWSToken == null
  //             ) {
  //               // do nothing
  //             } else {
  //               console.log("Decrypt error", err);
  //             }
  //           }
  //
  //           for (let i in data) {
  //             let data_result = data[i];
  //
  //             var log_title = "Log added";
  //             var event_metadata = data_result.metadata;
  //             var log_id = event_metadata.created_at;
  //
  //             var log_data = {
  //               id: log_id,
  //               title: log_title,
  //               log_type: "log_added",
  //               created: moment(event_metadata.created_at * 1000).toISOString(),
  //               metadata: event_metadata,
  //               is_discarded: false
  //             };
  //
  //             $this.$store.dispatch("logWS/addLog", {
  //               id: log_id,
  //               data: log_data
  //             });
  //             // $this.$store.dispatch("session/addGlobalAlertMessage", {
  //             //   message_text: "New log added",
  //             //   message_type: "success"
  //             // });
  //
  //             var logs = $this.$store.getters["logWS/getLogs"];
  //           }
  //         }
  //       } catch (err) {
  //         // console.log(err);
  //       }
  //     };
  //
  //     $this.logWS = log_ws;
  //   },
  //   disconnectLogWS: function () {
  //     if (this.logWS != null) {
  //       console.log("log_ws: Unsubscribing ...");
  //
  //       var param = {
  //         action: 2,
  //         topics: this.wsTopics
  //       };
  //
  //       // Unsubscribe camera
  //       this.logWS.send(JSON.stringify(param));
  //       console.log("log_ws: Unsubscribed ...");
  //
  //       this.logWS.close();
  //     }
  //   },
  //   createLog: function (log_dict) {
  //     // console.log("Create Log")
  //     var $this = this;
  //     // if (log_dict.data.event_id == undefined || log_dict.data.event_id == null || log_dict.data.event_id == "$event_id") {
  //     //     return "";
  //     // }
  //
  //     // Extract metadata
  //     var event_metadata = {};
  //     if (log_dict.data.metadata != undefined) {
  //       for (var k in log_dict.data.metadata) {
  //         if (log_dict.data.metadata[k] != "") {
  //           event_metadata[k] = log_dict.data.metadata[k];
  //         }
  //       }
  //     }
  //
  //     var log_id = event_metadata.created_at;
  //     var log_device_ip = log_dict.data.device_ip;
  //     var log_device_type = log_dict.data.device_type;
  //
  //     // object_str
  //     var log_title_parts = [];
  //     if (log_dict.data.object_str != "" && log_dict.data.object_str != null) {
  //       log_title_parts.push(log_dict.data.object_str);
  //     }
  //     // event_type
  //     var event_str = log_dict.type;
  //     if (log_dict.type == "log_added") {
  //       event_str = "Log Added";
  //     } else {
  //       var event_str_list = log_dict.type.split("_");
  //       event_str = event_str_list.join(" ");
  //       event_str = utils.titleCase(event_str, " ");
  //     }
  //
  //     if (
  //       log_dict.data.metadata != undefined &&
  //       log_dict.data.metadata.event_description
  //     ) {
  //       event_str = log_dict.data.metadata.event_description;
  //     }
  //     // console.log(event_str);
  //
  //     // Construct log title
  //     log_title_parts.push(event_str);
  //     var log_title = log_title_parts.join(" ");
  //     log_title = utils.titleCase(log_title, " ");
  //     // console.log(log_title)
  //
  //     var log_data = {
  //       id: log_id,
  //       title: log_title,
  //       log_type: log_dict.type,
  //       created: moment(log_dict.data.timestamp * 1000).toISOString(),
  //       metadata: event_metadata,
  //       is_discarded: false
  //     };
  //
  //     this.$store.dispatch("logWS/addLog", { id: log_id, data: log_data });
  //   }
  // }
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
